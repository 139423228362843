import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 753.82 755.3">
    <title>Logo</title>
    <path
      d="M850.54,768H96.72V12.7H850.54ZM102.92,389.55C104.61,493,140,582.37,213.48,655S376,763.75,479.42,762.71c100.6-1,187.31-37.41,258.44-108.16,74.47-74.07,110.86-164.89,109.55-269.92C846.16,283.88,809.34,196.86,738.26,126c-81.22-81-180.73-116.1-295-107.24-89.56,6.94-166.67,43.1-229.89,106.46C140.69,198,104.52,286.92,102.92,389.55Z"
      transform="translate(-96.72 -12.7)"
      fill="#0a192f"
    />
    <path
      d="M102.92,389.55c1.6-102.63,37.77-191.56,110.43-264.38C276.57,61.81,353.68,25.65,443.24,18.71,557.53,9.85,657,44.91,738.26,126c71.08,70.91,107.9,157.93,109.15,258.68,1.31,105-35.08,195.85-109.55,269.92C666.73,725.3,580,761.7,479.42,762.71,376,763.75,286.84,727.42,213.48,655S104.61,493,102.92,389.55Zm728.42.95C831.15,197,676.61,35.05,477.45,34.2c-200.7-.84-356.43,161.43-357,354.94-.62,197.12,158.09,357.2,353.83,357.45C672.1,746.83,831.17,588.22,831.34,390.5Z"
      transform="translate(-96.72 -12.7)"
      fill="#63fbd7"
    />
    <path
      d="M831.34,390.5c-.17,197.72-159.24,356.33-357.1,356.09-195.74-.25-354.45-160.33-353.83-357.45C121,195.63,276.75,33.36,477.45,34.2,676.61,35.05,831.15,197,831.34,390.5Zm-534.72-159,.72-.27c.32.74.66,1.47,1,2.21q27.21,66.79,54.41,133.6,29.06,71.6,58,143.23,26.24,64.6,52.57,129.18c.86,2.13,1.88,3,4.27,2.92,6-.19,12.06-.28,18.08,0,3.35.17,4.87-.89,6.15-4.05q27-66.87,54.29-133.63,51.76-126.75,103.62-253.45,4.14-10.09,8.3-20.19l.74.15V642.06h61.52V144.56a9.75,9.75,0,0,0-1.36-.21c-28.18,0-56.36,0-84.53-.08-2.23,0-3,1-3.64,2.78-4.51,11.4-9,22.82-13.64,34.16Q555.47,331.77,493.82,482.28c-5.51,13.46-10.91,27-16.64,41.15-2.57-6.67-4.8-12.75-7.23-18.75Q397.4,326.22,324.87,147.76c-1-2.5-2.15-3.52-5-3.5-27.07.12-54.14.07-81.21.07H235V642.06h61.58Z"
      transform="translate(-96.72 -12.7)"
    />
    <path
      d="M296.62,231.47V642.06H235V144.33h3.61c27.07,0,54.14,0,81.21-.07,2.86,0,4,1,5,3.5Q397.35,326.25,470,504.68c2.43,6,4.66,12.08,7.23,18.75,5.73-14.18,11.13-27.69,16.64-41.15q61.6-150.54,123.24-301.07c4.65-11.34,9.13-22.76,13.64-34.16.68-1.73,1.41-2.78,3.64-2.78,28.17.1,56.35.07,84.53.08a9.75,9.75,0,0,1,1.36.21v497.5H658.71V231.19L658,231q-4.15,10.1-8.3,20.19Q597.85,378,546.05,504.68q-27.27,66.77-54.29,133.63c-1.28,3.16-2.8,4.22-6.15,4.05-6-.3-12.06-.21-18.08,0-2.39.08-3.41-.79-4.27-2.92Q437,574.8,410.69,510.24q-29.06-71.6-58-143.23-27.13-66.83-54.41-133.6c-.3-.74-.64-1.47-1-2.21Z"
      transform="translate(-96.72 -12.7)"
      fill="#63fdd8"
    />
  </svg>
);

export default IconLogo;
